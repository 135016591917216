require.config({
  useMinified: false,
  paths: {
    'bootstrap-datepicker': 'ia/dist/plugins/bootstrap-datepicker.min',
    'jquery.selectric': 'ia/lib/plugins/jquery.selectric',
    'uuid': 'ia/app/commun/uuid',
    'utils': 'ia/app/commun/utils',
    'analytics_ia': 'Commun/lib/analytics_ia',
    'tooltip': 'Commun/dist/plugins/BootStrap/tooltip',
    'popover': 'Commun/lib/plugins/BootStrap/popover-ia',
    'matchMinHeight': 'Commun/dist/plugins/jquery.matchMinHeight.min',
    'video': 'Commun/dist/video',
    'elephants': 'Ia125/app/elephants/elephants',
    'elephants-employes': 'Ia125/app/elephants/elephants-employes',
    'achat-gagnant': 'Ia125/app/AchatGagnant/achat-gagnant',
    'votre-histoire': 'Ia125/app/AchatGagnant/votre-histoire',
    'voter-votre-histoire': 'Ia125/app/votre-histoire/voter-votre-histoire',
    'abonnement': 'Ia125/app/Abonnement/abonnement',
    'isotope': 'Ia125/lib/isotope',
    'rellax': 'Ia125/lib/rellax',
    'remodal': 'Ia125/lib/remodal',
    'tableau-gagnants.sommaire': 'Ia125/app/tableau-gagnants/tableau-gagnants.sommaire',
    'tableau-gagnants.complet': 'Ia125/app/tableau-gagnants/tableau-gagnants.complet',
    'salle-de-presse': 'Ia125/app/salle-de-presse/salle-de-presse',
    'meilleures-histoires': 'Ia125/app/votre-histoire/meilleures-histoires',
    'meilleures-projets': 'Ia125/app/concours-don/meilleures-projets',
    'sammy': 'ia/dist/sammy',
    'knockout': 'ia/dist/knockout',
    'knockout-extenders': 'ia/lib/plugins/knockout.extenders',
    'knockout-mapping': 'ia/dist/plugins/knockout.mapping.2.4.1',
    'compteur-caracteres': 'Commun/lib/plugins/compteur-caracteres',
    'compteur-mots': 'Commun/lib/plugins/compteur-mots',
    'fileupload': 'Commun/lib/plugins/fileupload',
    'jquery.fileupload': 'Commun/lib/plugins/jquery.fileupload',
    'jquery.ui.widget': 'Commun/dist/plugins/jquery.ui.widget',
    'jquery.iframe-transport': 'Commun/dist/plugins/jquery.iframe-transport',
    'jquery.validate.additionalMethods': 'Commun/lib/plugins/jquery.validate.additionalMethods',
    'commun-tuilerie': 'commun/lib/plugins/jquery.tuilerie',
    'commun-tuilerie-manager': 'commun/app/commun-tuiles',
    'quiz': 'commun/app/quiz/quiz',
    'quizController': 'commun/app/quiz/quizController',
    'quizCls': 'commun/app/quiz/quiz.cls',
    'pageTransitions': 'commun/lib/plugins/jquery.page-transitions.min',
    'concours-don': 'Ia125/app/concours-don/concours-don',
    'form-votes-don': 'Ia125/app/concours-don/form-votes-don',
    'bootstrap-advenced-btn': 'ia/dist/plugins/bootstrap-advanced-btn',
    'loadingGif': 'commun/lib/loadingGif',
    'arrow-top': 'Ia125/app/arrow-top'
  },
  shim: {
    'bootstrap-datepicker': {
      'deps': ['jquery']
    },
    'jquery.selectric': {
      'deps': ['jquery']
    },
    'tableau-gagnant.sommaire': {
      'deps': ['jquery', 'jquery.selectric', 'jquery.dataTables', 'datatables.responsive', 'datatables.bootstrap.responsive']
    },
    'tableau-gagnant.complet': {
      'deps': ['jquery', 'jquery.selectric', 'jquery.dataTables', 'datatables.responsive', 'datatables.bootstrap.responsive']
    },
    'matchMinHeight': {
      'deps': ['jquery']
    },
    'salle-de-presse': {
      'deps': ['jquery']
    },
    'jquery.fileupload': {
      'deps': ['jquery']
    },
    'jquery.ui.widget': {
      'deps': ['jquery']
    },
    'jquery.iframe-transport': {
      'deps': ['jquery']
    },
    'tooltip': {
      'deps': ['jquery']
    },
    'popover': {
      'deps': ['jquery', 'tooltip']
    },
    'commun-tuilerie-manager': {
      'deps': ['jquery']
    },
    'bootstrap-advenced-btn': {
      'deps': ['jquery']
    }
  },
  waitSeconds: '99'
});